<template lang="pug">
section#section-demo(ref="root")
  .wrapper
    .main-container
      div(:class="v" v-for="v,i in arr")
        .item-content(style="transform: translate3d(0px, 0px, 0px);" :ref=`v==='item-main'? 'itemMain' : v`)
            span.scroll.text-border Scroll Down.
            img(src="~@/assets/img-effect/pic-0.jpg", alt)

            h1
              | Hi, I'am Romain, a creative front-end developer and graphic designer from Liege/Belgium.
              br
              | Currently living and working in London/UK.
              br
              | I'm particulary interested in various printing process such as Silkscreen Printing, experimenting with Typography and Minimal site design.
            a.text-border.big(href="mailto:romain.granai@gmail.com") DROP ME A LINE
            .abc.big
              | ABCDEFGHIJKLMNOPQRSTUVWXYZzZzZzZzZ.
              br
              span.text-border 1234567890
            img(src="~@/assets/img-effect/pic-0.jpg", alt)
            .color-anim.more-stuff.h1 More stuff to come soon. Stay connected!!!
</template>
<script>
window.__DEBUG__ = false;

import { defineComponent, ref, onMounted } from '@vue/runtime-core';
import { addScrollListener } from '@zhinan-oppo/scroll-handle';
import anime from 'animejs';

export default defineComponent({
  setup() {
    const arr = ref(['item-top', 'item-main', 'item-bottom']);
    const root = ref();
    const itemMain = ref();
    onMounted(() => {
      const h = itemMain.value.offsetHeight;
      root.value.style.height = `${h}px`;
      const itemList = document.querySelectorAll('.item-content');
      const tl = anime.timeline({ autoplay: false, easing: 'easeOutExpo' }).add({
        targets: itemList,
        translateY: [0, -h],
        duration: 1000,
      });
      addScrollListener(root.value, {
        start: 'top',
        forceInViewBoundary: true,
        handlers: {
          inView({ distance, total }) {
            const p = distance / total;
            console.log(p);
            tl.seek(p * tl.duration);
          },
        },
      });
    });

    return { root, arr, itemMain };
  },
});
</script>

<style lang="stylus">

#section-demo
  .wrapper
    position sticky
    top 0
    left 0
    display -webkit-box
    display -ms-flexbox
    display flex
    -webkit-box-align center
    -ms-flex-align center
    align-items center
    -webkit-box-pack center
    -ms-flex-pack center
    justify-content center
    width 100%
    height 100vh
    position fixed
    -webkit-perspective 150px
    perspective 150px

  .main-container
    display -webkit-box
    display -ms-flexbox
    display flex
    -webkit-box-orient vertical
    -webkit-box-direction normal
    -ms-flex-direction column
    flex-direction column
    width 80vw
    height 90vh
    -webkit-perspective 200px
    perspective 200px
    text-align center

  .main-container>div
    height calc((100% / 3))
    overflow hidden
    -webkit-box-sizing border-box
    box-sizing border-box

  .item-top
    position relative
    padding-top 30vh
    -webkit-transform-origin bottom center
    -ms-transform-origin bottom center
    transform-origin bottom center
    -webkit-transform rotatex(30deg)
    -ms-transform rotatex(30deg)
    transform rotatex(30deg)
    margin-bottom -.5px

  .item-top:before
    content ''
    position absolute
    top 0
    left 0
    display block
    width 100%
    height 100px
    background -webkit-gradient(linear, left top, left bottom, from(#111111), to(rgba(17, 17, 17, 0)))
    background -webkit-linear-gradient(top, #111111, rgba(17, 17, 17, 0))
    background -o-linear-gradient(top, #111111, rgba(17, 17, 17, 0))
    background linear-gradient(to bottom, #111111, rgba(17, 17, 17, 0))
    z-index 1
    -webkit-backface-visibility hidden
    backface-visibility hidden

  .body--color-invert .item-top:before
    background -webkit-gradient(linear, left top, left bottom, from(#e9e9e9), to(rgba(233, 233, 233, 0)))
    background -webkit-linear-gradient(top, #e9e9e9, rgba(233, 233, 233, 0))
    background -o-linear-gradient(top, #e9e9e9, rgba(233, 233, 233, 0))
    background linear-gradient(to bottom, #e9e9e9, rgba(233, 233, 233, 0))

  .item-bottom
    -webkit-transform-origin top center
    -ms-transform-origin top center
    transform-origin top center
    -webkit-transform rotatex(40deg)
    -ms-transform rotatex(40deg)
    transform rotatex(40deg)
    margin-top -1px

  .item-bottom .item-content
    margin-top -30vh

  .item-bottom:before
    content ''
    position absolute
    bottom 0
    left 0
    display block
    width 100%
    height 100px
    background -webkit-gradient(linear, left top, left bottom, from(rgba(17, 17, 17, 0)), to(#111111))
    background -webkit-linear-gradient(top, rgba(17, 17, 17, 0), #111111)
    background -o-linear-gradient(top, rgba(17, 17, 17, 0), #111111)
    background linear-gradient(to bottom, rgba(17, 17, 17, 0), #111111)
    z-index 1
    -webkit-backface-visibility hidden
    backface-visibility hidden

  .body--color-invert .item-bottom:before
    background -webkit-gradient(linear, left top, left bottom, from(rgba(233, 233, 233, 0)), to(#e9e9e9))
    background -webkit-linear-gradient(top, rgba(233, 233, 233, 0), #e9e9e9)
    background -o-linear-gradient(top, rgba(233, 233, 233, 0), #e9e9e9)
    background linear-gradient(to bottom, rgba(233, 233, 233, 0), #e9e9e9)

  img
    width 100%
    height 1000px;
    margin-top 20px
    -webkit-filter invert(100%)
    filter invert(100%)
    -webkit-transition all .5s ease-in-out
    -o-transition all .5s ease-in-out
    transition all .5s ease-in-out

  .half
    width 50%

  .row
    display -webkit-box
    display -ms-flexbox
    display flex
</style>
